function inquiry_validation() {

    console.log('inquiry_validation running!');

    $('#inquiry-form').validate({
        //debug: true,
        //focusCleanup: false,

        rules: {
            name: {
                minlength: 2,
                required: true
            },
            company: {
                required: true
            },
            inquiry: {
                required: true
            },
            phone: {
                required: true,
                digits: true
            },
            email: {
                required: true,
                email: true
            },
            postnummer: {
                required: true
            },
            poststed: {
                required: true
            }

        },
        messages: {
            name: "Du må oppgi navn",
            company: "Du må oppgi firmanavn",
            inquiry: "Hva gjelder henvendelsen",
            phone: {
                required: "Oppgi telefonnummer",
                digits: "Oppgi gyldig telefonnummer"
            },
            email: {
                required: "Oppgi e-postadresse",
                email: "Oppgi e-postadresse"
            },
            postnummer: "Oppgi postnummer",
            poststed: "Oppgi poststed"
        },
        highlight: function (element) {
            $(element).closest('.form-group').removeClass('success').addClass('error');
        },
        success: function (element) {
            //element.text('OK!').addClass('valid')
            //  .closest('.form-group').removeClass('error').addClass('success');

            $(element).closest('.form-group').removeClass('error').addClass('success');
        },
        /*errorPlacement: function () {
         return true;
         },*/
        submitHandler: function(form) {

            // lock submit
            $(form).find(':submit').attr("disabled", true);

            // send data
            $.ajax({
                type: 'POST',
                url: '/wp-content/themes/sh-2020/lib/send-inquiry-request.php',
                data: $(form).serialize()
            })
                .done(function (response) {
                    //console.log('sent success!');

                    // show feedback
                    showInquiryResponse(form, 'success', response);

                })
                .fail(function(data) {

                    // show feedback
                    showInquiryResponse(form, 'error', data);

                });


        }
    });



    // update error message on select list option change
    $("#inquiry").change(function() {
        $("#inquiry-form").validate().element("[name=inquiry]");
    });

}



function showInquiryResponse(theForm, responseType, data) {

    if (responseType == 'success') {
        //console.log('SUCCESS', data);

        // show feedback message
        $('#confirmation-msg').text(data);
        $('#confirmation-msg').delay(0).fadeIn('fast');

        // empty inputs
        resetInquiryForm('#inquiry-form');

        // activate submit
        $(theForm).find(':submit').attr("disabled", false);

    } else {
        //console.log('ERROR', data);
        // show feedback message
        $('#confirmation-msg').text(data.responseText);
        $('#confirmation-msg').delay(0).fadeIn('fast');

        // activate submit
        $(theForm).find(':submit').attr("disabled", false);
    }

}





/*function hideModal(theModal) {
    $(theModal).modal('hide');

    // set form to idle state when modal is closed
    $(theModal).on('hidden.bs.modal', function () {
        resetForm('#inquiry-form');
    });
}*/

function resetInquiryForm(theForm) {
    // reset form error messages
    var validator = $( theForm ).validate();
    validator.resetForm();

    // clear form inputs
    $(theForm)[0].reset();

    // make the form visible again
    //$(theForm).show();
}
