function centerModal() {
    $(this).css('display', 'block');
    var $dialog = $(this).find(".modal-dialog");
    var offset = ($(window).height() - $dialog.height()) / 2;
    // Center modal vertically in window
    $dialog.css("margin-top", offset);
}

// center modal in viewport when opening
$('.modal').on('show.bs.modal', centerModal);

// reset form when closing modal
$('.modal').on('hidden.bs.modal', function () {

    //var validator = $( "#inquiry-form", "#course-signup-form" ).validate();
    //validator.resetForm();

    // hide form-response messages
    $(this).find('#confirmation-msg').empty();

})

// Center modal in viewport on resize
$(window).on("resize", function () {
    $('.modal:visible').each(centerModal);
});