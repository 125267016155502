function mapPopups() {
	// console.log('Quiz map popups is running!');
	var $body = $(document.body),
		$closeBtn = $('.popup-wrapper .close-btn'),
		$openBtn = $('.popup-wrapper .open-btn'),
		isOpened = false;
		$popups = $(".popup-afr, .popup-asia, .popup-ma, .popup-sa");

	
	// Open - close popup. Hover regions
	if($openBtn.length > 0) {
		$openBtn.click(function(e){
			var $this = $(this),
				$currentPopup = $this.closest('.popup-wrapper');
			// Open popup
			if(!isOpened) {
				isOpened = true;
				if($body.hasClass('popup-hover')) {
					$body.removeClass('popup-hover');
					$currentPopup.removeClass('popup-hover');
				}
				$body.addClass('popup-opened');
				$currentPopup.addClass('popup-opened');
			}
			// Close popup
			if($closeBtn.length > 0) {
				$closeBtn.click(function(e){
					isOpened = false;
					$body.removeClass('popup-opened');
					$currentPopup.removeClass('popup-opened');
				});
			}
		});
		// Regions hover
		$openBtn.hover(function(e){
			// Over
			var $this = $(this),
				$currentPopup = $this.closest('.popup-wrapper');
				$body.addClass('popup-hover');
				$currentPopup.addClass('popup-hover');
		}, function(e){
			// Out 
			var $this = $(this),
				$currentPopup = $this.closest('.popup-wrapper');
				$body.removeClass('popup-hover');
				$currentPopup.removeClass('popup-hover');
		});
	}

	// Close intro popup
	var $closeIntroPopup = $('.close-intro-btn'),
		$introPopup = $('#intro-popup'),
		introPopupOpened = true;

	if($closeIntroPopup.length > 0) {
		$closeIntroPopup.click(function(e){
			e.preventDefault();
			if(introPopupOpened) {
				introPopupOpened = false;
				$body.removeClass('intro-popup-opened');
				setTimeout(function(){
				    $introPopup.remove();
				}, 1000);
			}
			// Show clickable areas and fade in 1.5s
			if ($(window).width() > 1399) {
				$popups.addClass('popup-hover');
				$body.addClass('popup-hover');
				setTimeout(function() {
				   $popups.removeClass('popup-hover');
				   $body.removeClass('popup-hover');
				}, 1500);
			}
		});
	}
}