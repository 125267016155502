// Setting navbar to fixed top on smallest displays
var screen_xs = 767;

if (window.innerWidth <= screen_xs) {
    $(".navbar").addClass("navbar-fixed-top");
    $("body").css("padding-top", $(".navbar-fixed-top").height());

} else {
    //$(".navbar").addClass("navbar-default");
    $(".navbar").removeClass("navbar-fixed-top");
}

$(window).resize(function() {
    if (window.innerWidth <= screen_xs) {
        $(".navbar").addClass("navbar-fixed-top");
        $("body").css("padding-top", $(".navbar-fixed-top").height());
    } else {
        $(".navbar").removeClass("navbar-fixed-top");
        $("body").css("padding-top", 0);
    }
});


// Adding SH logo to main navigation
function buildMainNavigation() {
    var logoPos = Math.floor($('#menu-primary-navigation li').length / 2);
    $("#menu-primary-navigation li").eq(logoPos)
        .after(
        $('<li class="menu-brand"><a title="Solberg & Hansen" alt="Solberg & Hansen" href="/" class="text-hide">Solberg & Hansen</a></li>')
    );
}