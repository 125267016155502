/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Quiz Map popups
        mapPopups();

        // Animateed navbar toggle
        $(".navbar-toggle").on("click", function () {
          $(this).toggleClass("collapsed");
        });


        // Fade in pages
        document.getElementsByClassName('wrap')[0].style.opacity = 1;

        // Adding logo to main nav
        buildMainNavigation();

        // Instanciate global search controller;
        window.search = new Search();
        window.search.init();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        newsletter_signup_validation();
        smoothScroll.init();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'nyhetsbrev': {
      init: function() {
        // JavaScript to be fired on the home page
        newsletter_signup_validation();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // Om Solberg & Hansen page, note the change from about-us to about_us.
    'om_solberg_hansen': {
      init: function() {
        // JavaScript to be fired on the about us page

        // init map
        init_map();

        // init departments list
        toggle_departments();

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    // Bli-forhandler page, note the change from about-us to about_us.
    'kaffeleverandor': {
      init: function() {
        // JavaScript to be fired on the about us page

        inquiry_validation();

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    // kundeforespørsel-forhandler page.
    'kundeforesporsel': {
      init: function() {
        // JavaScript to be fired on the about us page

        inquiry_validation();

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    // Kunderregistrerings siden
    'kunderegistrering': {
      init: function() {
        // JavaScript to be fired on the about us page
        //console.log('kunderegistreringsskjema');

        client_registration_validation();

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    // Single journal
    'single_journal': {
      init: function() {
        // JavaScript to be fired on the about us page
        single_journal_heading();
      }
    },
    // Single kurs
    'single_kurs': {
      init: function() {
        // init map
        init_map();
        course_signup_validation();
      }
    },
    // Wiki
    'post_type_archive_wiki': {
      init: function() {
        // JavaScript to be fired on the about us page
        create_wiki_alfabet_menu();
        create_small_wiki_alfabet_menu();
        //sticky_index();
      }
    }
  };


  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  // $(document).ready(function(){
  // $('.page-main-image').css('height', window.innerHeight * 0.7);
  // });


})(jQuery); // Fully reference jQuery after this point.
