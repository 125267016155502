function newsletter_signup_validation() {

    //console.log('course_signup_validation running!');

    $('#newsletter-signup-form').validate({
        focusCleanup: false,
        errorPlacement: function(error, element) {
            error.insertAfter( element.parent("div") );
        },
        wrapper: "div",
        rules: {
            'accept-terms': {
                required: true
            },
            email: {
                required: true,
                email: true
            }
        },
        messages: {
            'accept-terms': {
                required: "Du må bekrefte å ha lest og godkjent våre vilkår.",
            },
            email: {
                required: "Oppgi e-postadresse",
                email: "Oppgi e-postadresse"
            }
        },
        highlight: function (element) {
            $(element).closest('.input-group').removeClass('success').addClass('error');
        },
        success: function (label, element) {
            label.parent().removeClass('error');
            label.remove();
            //element.text('OK!').addClass('valid')
            //  .closest('.form-group').removeClass('error').addClass('success');

            //$(element).closest('.input-group').removeClass('error').addClass('success');
        },
        /*errorPlacement: function () {
            return true;
        },*/

        submitHandler: function(form) {

            // lock submit
            $(form).find(':submit').attr("disabled", true);

            // send data
            $.ajax({
                type: 'POST',
                url: '/wp-content/themes/sh-2020/lib/send-newsletter-signup-registration.php',
                data: $(form).serialize()
            })
                .done(function (response) {

                    // show feedback
                    showNewsletterResponse(form, 'success', response);

                })
                .fail(function(data) {

                    // show feedback
                    showNewsletterResponse(form, 'error', data);

                });

        }
    });

}

function showNewsletterResponse(theForm, responseType, data) {

    if (responseType == 'success') {
        //console.log('SUCCESS', data);

        var validator = $( theForm ).validate();

        $(theForm).find('.input-group').fadeOut('fast', function () {
            // reset validation
            validator.resetForm();

            // clear form inputs
            $(theForm)[0].reset();

            // show feedback message
            $('#confirmation-msg').text(data);
            $('#confirmation-msg').delay(0).fadeIn('fast');
            $('#confirmation-msg').delay(3000).fadeOut('fast');

            // activate submit
            $(theForm).find(':submit').attr("disabled", false);

            // show input again
            $(this).delay(3410).fadeIn('fast');
        });


    } else {
        console.log('ERROR', data);
        // show feedback message
        $('#confirmation-msg').text(data.responseText);
        $('#confirmation-msg').delay(0).fadeIn('fast');
        $('#confirmation-msg').delay(3000).fadeOut('fast');

        // activate submit
        $(theForm).find(':submit').attr("disabled", false);
    }

}
