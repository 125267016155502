function single_journal_heading() {
    
    var heading = $('.entry-title-hidden');
    
    $(window).scroll(function() {
       if($('.entry-title').inView('bottomOnly')) {
       $( heading ).removeClass( "entry-title-visible" ).addClass("entry-title-hidden");
       } else {
           $( heading ).removeClass( "entry-title-hidden" ).addClass("entry-title-visible");
       }
    });

}