/**
 * Created by marius.gratte on 29.06.15.
 */
function create_wiki_alfabet_menu() {

    var letters = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','æ','ø','å'];
    for (var i = 0; i <= letters.length -1; i++) {
        if (document.getElementById(letters[i])) {
            console.log('found!');
            $('.wiki-alfabet-index').append('<a href="#' + letters[i] + '" class="btn" role="button">' + letters[i] + '</a>');
        } else {
            $('.wiki-alfabet-index').append('<a href="#' + letters[i] + '" class="btn disabled" role="button">' + letters[i] + '</a>');

        }
    }

}

function create_small_wiki_alfabet_menu() {

    var lettersGroupOne = ['a','b','c','d','e','f','g'];
    var lettersGroupTwo = ['h','i','j','k','l','m','n'];
    var lettersGroupThree = ['o','p','q','r','s','t','u'];
    var lettersGroupFour = ['v','w','x','y','z','æ','ø','å'];

    for (var i = 0; i <= lettersGroupOne.length -1; i++) {
        if (document.getElementById(lettersGroupOne[i])) {
            console.log('found!');
            $('.wiki-alfabet-index-small').append('<a href="#' + lettersGroupOne[i] + '" class="btn" role="button">' + lettersGroupOne[0] + ' - ' + lettersGroupOne[lettersGroupOne.length - 1] + '</a>');
            break;
        }
    }

    for (var i = 0; i <= lettersGroupTwo.length -1; i++) {
        if (document.getElementById(lettersGroupTwo[i])) {
            console.log('found!');
            $('.wiki-alfabet-index-small').append('<a href="#' + lettersGroupTwo[i] + '" class="btn" role="button">' + lettersGroupTwo[0] + ' - ' + lettersGroupTwo[lettersGroupTwo.length - 1] + '</a>');
            break;
        }
    }

    for (var i = 0; i <= lettersGroupThree.length -1; i++) {
        if (document.getElementById(lettersGroupThree[i])) {
            console.log('found!');
            $('.wiki-alfabet-index-small').append('<a href="#' + lettersGroupThree[i] + '" class="btn" role="button">' + lettersGroupThree[0] + ' - ' + lettersGroupThree[lettersGroupThree.length - 1] + '</a>');
            break;
        }
    }

    for (var i = 0; i <= lettersGroupFour.length -1; i++) {
        if (document.getElementById(lettersGroupFour[i])) {
            console.log('found!');
            $('.wiki-alfabet-index-small').append('<a href="#' + lettersGroupFour[i] + '" class="btn" role="button">' + lettersGroupFour[0] + ' - ' + lettersGroupFour[lettersGroupFour.length - 1] + '</a>');
            break;
        }
    }

}

