var Search = function() {
  this.searchDelayID;
};

var search;


/** Initialise the search class, basically a cunstructor
 * @return boolean
 */
Search.prototype.init = function() {
  var that = this;
  $('.form.search-form')
    .find('input.query')
    .on('keyup', $.proxy(that.keyUpEventHandler, this));
  $('.navbar-header .search-toggle').on('click', $.proxy(that.show, this));
  $('.form.search-form button.close').on('click', $.proxy(that.hide, this));

  return true;
};

/** Toggle hiding and showing of the search bar
 * @return boolean
 */
Search.prototype.hideShow = function() {
  var self = this;
  // if (!!shopType) {
    $('.shop-select .dropdown-toggle').addClass('disabled');
    if ($('.search-bar').hasClass('visible')) {
      self.hide();
    } else {
      self.show();
    }
  // } else {
    // Show dropdown
  // }
};

/** Show the search bar
 * @return boolean
 */
Search.prototype.show = function() {
  $('.search-bar').addClass('visible');
  $('.navbar-header').addClass('give-way-for-search');
  $('.header-pre-nav').addClass('nudge');
  $('.search-bar input').focus();
  $('.search-bar input').val('');
  $('.btn-search-bar span')
    .removeClass('icon-search')
    .addClass('icon-close');

  /*Collapse the navbar if the menu is exoanded */

  if ($('.navbar-toggle').hasClass('collapsed') != true) {
    $('.navbar-toggle').click();
  }

  return false;
};

/** Hide the search bar
 * @return boolean
 */
Search.prototype.hide = function() {
  var that = this;

  $('.search-bar').removeClass('visible');
  $('.navbar-header').removeClass('give-way-for-search');
  $('.header-pre-nav').removeClass('nudge');
  $('.btn-search-bar span')
    .addClass('icon-search')
    .removeClass('icon-close');

  $('.search-results').addClass('collapse');
  $('.search-results').removeClass('expand');

  that.emptySearchResults();

  return false;
};

/** Do the actual search by ajax calling.
 * @return boolean
 */
Search.prototype.doSearch = function(queryString) {
  var that = this,
      $shopTypeSelect = $('.shop-type-select');

  $shopTypeSelect.addClass('disabled');
  if (queryString === '') {
    that.emptySearchResults();
    return;
  }

  var shopParam;

  if (shopType ==='privat') {
    shopParam = '&webshop=shop';
  } else {
    shopParam = '&webshop=b2bshop';
  }

  var jqxhr = $
    .ajax({
      url: window.AjaxSearch.ajax_url + '&search_query=' + queryString + shopParam
    })
    .done(function(payload) {
      that.emptySearchResults();
      /* Empty any previous search results */
      if (typeof payload.data != 'undefined') {
        that.fillSearchResults(payload.data);
      }
      $('.search-bar input').removeClass('waiting');
      $shopTypeSelect.removeClass('disabled');
    })
    .fail(function(payload) {$shopTypeSelect.removeClass('disabled');})
    .always(function(payload) {});
};

/** Empties the search result and hides the categories
 * @return true;
 */
Search.prototype.emptySearchResults = function() {
  $('.result-container').empty();
  $('.result-container').hide();
  $('.result-headline').hide();
};

/** Fill the search results based on incoming payload
 *
 * @param payload The JSON payload containing aggregated
 * search results from both Wordpress and eSHOP.
 * @return boolean The results
 */
Search.prototype.fillSearchResults = function(payload) {
  var template, totalCount;

  for (var category in payload) {
    var itemCount = 0;

    if (category === 'products') {
      /* Loop the nested product tree and fetch
         coffee, tee and other.
         */
      for (var productCategory in payload[category]) {
        for (var product in payload[category][productCategory]) {
          var item = payload[category][productCategory][product];

          template = $('.product-template a').clone();
          $(template).attr('href', item.url);
          $(template).attr('data-id', item.id);
          $(template)
            .find('h2.title')
            .html(item.title);
          $(template)
            .find('p.article_nr')
            .html(item.article_nr);
          $(template)
            .find('p.text')
            .html(item.text);


          if (item.image !== '') {
            $(template)
              .find('img')
              .attr('src', item.image);
          } else {
            $(template)
              .find('img')
              .hide();
          }

          $('.result-container.products').append(template);

          itemCount++;
        }
      }
    } else {
      for (var result in payload[category]) {
        var item = payload[category][result];

        template = $('.default-template a').clone();

        $(template).attr('href', item.url);
        $(template)
          .find('h2.title')
          .html(item.title);
        $(template)
          .find('p.text')
          .html(item.text);
        $(template)
          .find('p.ingress')
          .html(item.ingress);

        $('.result-container.' + category).append(template);

        itemCount++;
      }
    }

    // Update the totalCount before continuing on the ext category
    totalCount += itemCount;

    /* Regardless of category, show or hide the header or wrapper of each content
       depending on hit count = 0 or higher.
       */
    if (payload[category].length !== 0) {
      $('.result-container.' + category).show();
      $('.result-headline.' + category).show();
    }

    $('.result-headline.' + category + ' .count').html('(' + itemCount + ')');
  }

  if (totalCount === 0) {
    $('.result-error').show();
  } else {
    $('.result-error').hide();
  }

  $('.search-results').addClass('expand');
  $('.search-results').removeClass('collapse');

  return true;
};

/* ------------------------------------------------ */
/* ------------- EVENT HANDLERS ------------------- */
/* ------------------------------------------------ */

/** Do a new search after each keystroke, but only
 * if keystroke is more than 200ms apart
 * @param e
 */
Search.prototype.keyUpEventHandler = function(e) {
  var that = this,
    queryString;

  queryString = $(e.currentTarget).val();

  $('.search-bar input').addClass('waiting');

  clearTimeout(that.searchDelayID);

  that.searchDelayID = setTimeout($.proxy(that.doSearch, this), 200, queryString);
};



// Get/set/delete cookies for shop types (search)
function getCookie(name) {
  var matches = document.cookie.match(
    new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value, options) {
  options = options || {};
  var expires = options.expires;
  if (typeof expires == 'number' && expires) {
    var d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    expires = options.expires = d;
  }
  if (expires && expires.toUTCString) {
    options.expires = expires.toUTCString();
  }
  value = encodeURIComponent(value);
  var updatedCookie = name + '=' + value;
  for (var propName in options) {
    updatedCookie += '; ' + propName;
    var propValue = options[propName];
    if (propValue !== true) {
      updatedCookie += '=' + propValue;
    }
  }
  document.cookie = updatedCookie;
}
var shopType = getCookie('shop_type');

// Check if cookie is already set, set 'business' if not
function cookieCheck(){
  if(shopType) {
    var selector = '#radio-' + shopType;
    $(selector).prop('checked',true);
  }
}
cookieCheck();

$("#radio-business").on('change', function(e) {
  setCookie('shop_type', 'business');
  $('.search-form .query').val('');
  window.search.emptySearchResults();
  shopType = getCookie('shop_type');
  $('#category-1').prop('href', 'https://b2bshop.sh.no/kategori/kaffe');
  $('#category-2').prop('href', 'https://b2bshop.sh.no/kategori/te');
  $('#category-3').prop('href', 'https://b2bshop.sh.no/kategori/tilbehor');
});
$("#radio-privat").on('change', function(e) {
  setCookie('shop_type', 'privat');
  $('.search-form .query').val('');
  window.search.emptySearchResults();
  shopType = getCookie('shop_type');
  $('#category-1').prop('href', 'http://shop.sh.no/kategori/kaffe');
  $('#category-2').prop('href', 'http://shop.sh.no/kategori/te');
  $('#category-3').prop('href', 'http://shop.sh.no/kategori/tilbehor');
});

// Category modals / cookies
function categorySelect() {
  $('.category-item > a').each(function(){
    var $itemLink = $(this),
        $body = $('body'),
        $item = $itemLink.closest('.category-item'),
        $privateLink = $item.find('.modal-link-private'),
        $businessLink = $item.find('.modal-link-business');

    $itemLink.on('click', function(e){
      if(!shopType){
        e.preventDefault();
        $item.addClass('show-modal');
      }
    });
    $privateLink.on('click', function(e){
      setCookie('shop_type', 'privat');
    });
    $businessLink.on('click', function(e){
      setCookie('shop_type', 'business');
    });
    $body.click(function(event) {
      if (!$(event.target).closest($item).length) {
        $item.removeClass('show-modal');
      }
    });
  });
}
categorySelect();



//Choose cookie modal on search
// $('.shop-select .shop-private').on('click', function(e) {
//   setCookie('shop_type', 'private');
//   shopType = getCookie('shop_type');
//   window.search.hideShow();
// });
// $('.shop-select .shop-business').on('click', function(e) {
//   setCookie('shop_type', 'business');
//   shopType = getCookie('shop_type');
//   window.search.hideShow();
// });

// if(shopType) {
//   if(shopType === 'business'){
//     $("#radio-business").prop('checked',true);
//   }else{
//     $("#radio-privat").prop('checked',true);
//   }
// }



// Clear cookies temporary button
function deleteCookie(name) {
  setCookie(name, '', {
    expires: -1
  });
}
// $('#clear-cookie').on('click', function(e) {
//   e.preventDefault();
//   deleteCookie('shop_type');
//   location.reload(true);
// });

//////////////////////
