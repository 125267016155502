function client_registration_validation() {

    //console.log('course_signup_validation running!');

    $('#client-registation-form').validate({
        focusCleanup: false,

        rules: {
            companyVisitorName: {
                required: true
            },
            companyVisitorEmail: {
                required: true,
                email: true
            },
            companyVisitorAddress: {
                required: true
            },
            companyVisitorZipCode: {
                required: true
            },
            companyVisitorCity: {
                required: true
            },
            companyVisitorPhone: {
                required: true,
                digits: true
            },
            companyBillingName: {
                required: true
            },
            companyBillingEmail: {
                required: true,
                email: true
            },
            companyBillingAddress: {
                required: true
            },
            companyBillingZipCode: {
                required: true
            },
            companyBillingCity: {
                required: true
            },
            companyPostalName: {
                required: true
            },
            companyPostalAddress: {
                required: true
            },
            companyPostalZipCode: {
                required: true
            },
            companyPostalCity: {
                required: true
            },
            companyContactName: {
                required: true
            },
            companyContactPhone: {
                required: true,
                digits: true
            },
            companyContactEmail: {
                required: true,
                email: true
            },
            companyContactBossName: {
                required: true
            },
            companyContactBossPhone: {
                required: true,
                digits: true
            },
            companyContactBossEmail: {
                required: true,
                email: true
            },
            companyOrgNo: {
                required: true
            },
            companyType: {
                required: true
            },
            certificate: {
                required: false,
                extension: "pdf"
            },
            agree: {
                required: true
            }
        },
        messages: {
            companyVisitorName: "Du må oppgi firmanavn",
            companyVisitorEmail: "Oppgi e-postadresse",
            companyVisitorAddress: "Oppgi adresse",
            companyVisitorZipCode: "Oppgi postnummer",
            companyVisitorCity: "Oppgi sted",
            companyVisitorPhone: {
                required: "Oppgi telefonnummer",
                digits: "Oppgi gyldig telefonnummer"
            },
            companyBillingName: "Du må oppgi firmanavn",
            companyBillingEmail: "Oppgi e-postadresse",
            companyBillingAddress: "Oppgi adresse",
            companyBillingZipCode: "Oppgi postnummer",
            companyBillingCity: "Oppgi sted",
            companyPostalName: "Du må oppgi firmanavn",
            companyPostalAddress: "Oppgi adresse",
            companyPostalZipCode: "Oppgi postnummer",
            companyPostalCity: "Oppgi sted",
            companyContactName: "Oppgi kontaktnavn",
            companyContactPhone: {
                required: "Oppgi telefonnummer",
                digits: "Oppgi gyldig telefonnummer"
            },
            companyContactEmail: "Oppgi e-postadresse",
            companyContactBossName: "Oppgi kontaktnavn",
            companyContactBossPhone: "Oppgi gyldig telefonnummer",
            companyContactBossEmail: "Oppgi e-postadresse",
            companyOrgNo: "Oppgi organisasjonsnummer",
            companyType: "oppgi kundegruppe",
            certificate: {
                required: "Last opp firmaattest",
                extension: "Kun PDF-filer er tillatt"
            },
            agree: "Vennligst bekreft"
        },
        highlight: function (element) {
            $(element).closest('.form-group').removeClass('success').addClass('error');
        },
        success: function (element) {
            $(element).closest('.form-group').removeClass('error').addClass('success');
        },
        /*errorPlacement: function () {
            return true;
        },*/
        errorPlacement: function (error, element) {
            if (element.is(":radio") || element.is(":checkbox")) {
                error.appendTo(element.parent());
            } else {
                error.insertAfter(element);
            }
        },
        submitHandler: function(form) {

            // lock submit
            $(form).find(':submit').attr("disabled", true);

            // Collect data from inputs
            var fd = new FormData(form);

            // Add file to formdata object
            fd.append( 'certificate', $('#certificate')[0].files[0]);


            // send data
            $.ajax({
                type: 'POST',
                url: '/wp-content/themes/sh-2020/lib/send-client-registration.php',
                data: fd,
                processData: false,
                contentType: false
            })
                .done(function (response) {
                    console.log('sent success!', response);

                    // show feedback
                    showClientRegistrationResponse(form, 'success', response);

                })
                .fail(function(data) {
                    console.log('sent error!', data);

                    // show feedback
                    showClientRegistrationResponse(form, 'error', data);

                });

        }
    });

    function hideModal(theModal) {
        $(theModal).modal('hide');

        // set form to idle state when modal is closed
        $(theModal).on('hidden.bs.modal', function () {
            resetForm('#client-registation-form');
        });
    }

    function resetForm(theForm) {
        // reset form error messages
        var validator = $( theForm ).validate();
        validator.resetForm();

        // clear form inputs
        $(theForm)[0].reset();

        // make the form visible again
        $(theForm).show();
    }

    // update error message on select list option change
   /* $("#course").change(function() {
        $("#client-registation-form").validate().element("[name=course]");
    });*/

     /*$("#companyBillingAutoFill").change(function() {
     $("#client-registation-form").validate().element("[name=course]");
     });*/




    /*
    * Copy values from other part of form
     */

    $("#companyBillingAutoFill").on("change",function() {
        if( $("#companyBillingAutoFill").is(':checked') ) {
            $("#companyBillingName").val($('#companyVisitorName').val());
            $("#companyBillingEmail").val($('#companyVisitorEmail').val());
            $("#companyBillingAddress").val($('#companyVisitorAddress').val());
            $("#companyBillingZipCode").val($('#companyVisitorZipCode').val());
            $("#companyBillingCity").val($('#companyVisitorCity').val());
        } else {
            $('#companyBillingName').val('');
            $("#companyBillingName").val('');
            $("#companyBillingEmail").val('');
            $("#companyBillingAddress").val('');
            $("#companyBillingZipCode").val('');
            $("#companyBillingCity").val('');
        }

        // validate the form
        validateAndClearErrorMessages('#client-registation-form');

    });


    $("#companyPostalAutoFillVisitor").on("change",function() {

        $('#companyPostalAutoFillBilling').prop('checked', false); // Unchecks it


        if( $("#companyPostalAutoFillVisitor").is(':checked') ) {
            $("#companyPostalName").val($('#companyVisitorName').val());
            $("#companyPostalAddress").val($('#companyVisitorAddress').val());
            $("#companyPostalZipCode").val($('#companyVisitorZipCode').val());
            $("#companyPostalCity").val($('#companyVisitorCity').val());
        } else {
            $('#companyPostalName').val('');
            $("#companyPostalAddress").val('');
            $("#companyPostalZipCode").val('');
            $("#companyPostalCity").val('');
        }

        // validate the form
        validateAndClearErrorMessages('#client-registation-form');

    });

    $("#companyPostalAutoFillBilling").on("change",function() {

        $('#companyPostalAutoFillVisitor').prop('checked', false); // Unchecks it

        if( $("#companyPostalAutoFillBilling").is(':checked') ) {
            $("#companyPostalName").val($('#companyBillingName').val());
            $("#companyPostalAddress").val($('#companyBillingAddress').val());
            $("#companyPostalZipCode").val($('#companyBillingZipCode').val());
            $("#companyPostalCity").val($('#companyBillingCity').val());
        } else {
            $('#companyPostalName').val('');
            $("#companyPostalAddress").val('');
            $("#companyPostalZipCode").val('');
            $("#companyPostalCity").val('');
        }

        // validate the form
        validateAndClearErrorMessages('#client-registation-form');

    });

    $("#companyContactAutoFillBoss").on("change",function() {

        if( $("#companyContactAutoFillBoss").is(':checked') ) {
            $("#companyContactBossName").val($('#companyContactName').val());
            $("#companyContactBossPhone").val($('#companyContactPhone').val());
            $("#companyContactBossEmail").val($('#companyContactEmail').val());
        } else {
            $('#companyContactBossName').val('');
            $("#companyContactBossPhone").val('');
            $("#companyContactBossEmail").val('');
        }

        // validate the form
        validateAndClearErrorMessages('#client-registation-form');

    });



    function validateAndClearErrorMessages(theForm) {
        var validator = $(theForm).validate();
        validator.resetForm();
        //validator.hideErrors();
        $(theForm).trigger('submit');
    }


    // update error message on select list option change
    $("#companyType").change(function() {
        $("#client-registation-form").validate().element("[name=companyType]");
    });

}


function showClientRegistrationResponse(theForm, responseType, data) {
    console.log('show resonse now!');

    if (responseType == 'success') {
        console.log('SUCCESS', data);


        // show feedback message
        $('#confirmation-msg').text(data);
        $('#confirmation-msg').delay(0).fadeIn('fast');

        // empty inputs
        resetClientRegistrationForm('#client-registation-form');

        // activate submit
        $(theForm).find(':submit').attr("disabled", false);


        // show feedback message
        //$('#confirmation-msg').text(data);
        //$('#confirmation-msg').delay(0).fadeIn('fast');


        /*$(theForm).fadeOut('fast', function () {

            // show feedback message
            $('#confirmation-msg').text(data);
            $('#confirmation-msg').delay(0).fadeIn('fast');

            // hide feedback and close modal
            //$('#confirmation-msg').delay(3000).fadeOut('fast');

            // activate submit
            //$(theForm).find(':submit').attr("disabled", false);

        });*/


    } else {
        console.log('ERROR', data);
        // show feedback message
        $('#confirmation-msg').text(data.responseText);
        $('#confirmation-msg').delay(0).fadeIn('fast');
        $('#confirmation-msg').delay(3000).fadeOut('fast');

        // activate submit
        $(theForm).find(':submit').attr("disabled", false);
    }

}




// Add method
$.validator.addMethod("extension", function(value, element, param) {
    param = typeof param === "string" ? param.replace(/,/g, "|") : "png|jpe?g|gif";
    return this.optional(element) || value.match(new RegExp(".(" + param + ")$", "i"));
}, $.validator.format("Please enter a value with a valid extension."));




function resetClientRegistrationForm(theForm) {
    // reset form error messages
    var validator = $( theForm ).validate();
    validator.resetForm();

    // clear form inputs
    $(theForm)[0].reset();

    // make the form visible again
    //$(theForm).show();
}
