/**
 * Created by marius.gratte on 07.02.16.
 */

(function($) {

    smoothScroll.init({
        selector: '[data-scroll]', // Selector for links (must be a valid CSS selector)
        selectorHeader: '.navbar-fixed-top', // Selector for fixed headers (must be a valid CSS selector)
        speed: 600, // Integer. How fast to complete the scroll in milliseconds
        easing: 'easeInOutCubic', // Easing pattern to use
        offset: 25, // Integer. How far to offset the scrolling anchor location in pixels
        scrollOnLoad: true, // Boolean. If true, animate to anchor on page load if URL has a hash
        callback: function ( toggle, anchor ) {} // Function to run after scrolling
    });

})(jQuery);