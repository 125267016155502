// Toggle visible employees based on department

function toggle_departments() {

    $('li').on('click', 'a#toggleDepartments:not(.active)', function() {
        var target_dept = $(this).attr('data-dept');
        
        $('.employee-grid').fadeOut( 'fast', function() {    
            if (target_dept === 'all') {
                $('.employee').show();
            } else {
                // $('.employee' + '.'+target_dept).show().siblings().hide();
                // $('.employee' + '.'+target_dept).hide();
                 // $('.employee' + '.'+target_dept).show();
                 $('.employee' + '.'+target_dept).show();
                 $('.employee').not('.'+target_dept).hide();
            }
        }).fadeIn('fast');
        
        // set active on choosen menuitem
        $(this).addClass('active').parent().siblings().children().removeClass('active');

    });
    
}
