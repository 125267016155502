function init_map() {
    
    $(document).ready(function(){
        $('.acf-map').each(function(){
            render_map( $(this) );
        });
    });
    
}

function render_map( $el ) {
        
    // var
    var $markers = $el.find('.marker');
    
    // vars
    var args = {
        zoom        : 16,
        center      : new google.maps.LatLng(0, 0),
        mapTypeId   : google.maps.MapTypeId.ROADMAP
    };
    
    // create map               
    var map = new google.maps.Map( $el[0], args);
    
    // add a markers reference
    map.markers = [];
    
    // add markers
    $markers.each(function(){
        add_marker( $(this), map );
    });
    
    // Styles
    var styles = [
      {
        elementType: "geometry",
        stylers: [
          // { visibility: "simplified" },
          { "saturation": -100 },
          { "lightness": 23 }
        ]
      }
    ];
    map.setOptions({styles: styles});
    
    // center map
    center_map( map );
    
    // add event when resizing browser window
    google.maps.event.addDomListener(window, 'resize', function() {
          center_map( map );
        });
}
        
function add_marker( $marker, map ) {
    
        // var
    var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
    
    // create marker
    // var pinImage = new google.maps.MarkerImage("http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|232222|f4f4f4");
    var marker = new google.maps.Marker({
        position    : latlng,
        map         : map,
        // icon        : pinImage,
        optimized   : true
    });
    
    // add to array
    map.markers.push( marker );
    
    // if marker contains HTML, add it to an infoWindow
    if( $marker.html() )
    {
        // create info window
        var infowindow = new google.maps.InfoWindow({
            content     : $marker.html()
        });
    
        // show info window when marker is clicked
        google.maps.event.addListener(marker, 'click', function() {
                infowindow.open( map, marker );
            });
        }
    
}
        
        
function center_map( map ) {
    
        // vars
    var bounds = new google.maps.LatLngBounds();
    
    // loop through all markers and create bounds
    $.each( map.markers, function( i, marker ){
        var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
        bounds.extend( latlng );
    });
    
    // only 1 marker?
    if( map.markers.length === 1 )
    {
        // set center of map
        map.setCenter( bounds.getCenter() );
        map.setZoom( 17 );
    }
    else
    {
        // fit to bounds
            map.fitBounds( bounds );
        }
    
}