function course_signup_validation() {

    //console.log('course_signup_validation running!');

    $('#course-signup-form').validate({
        focusCleanup: false,

        rules: {
            course: {
                required: true
            },
            name: {
                minlength: 2,
                required: true
            },
            email: {
                required: true,
                email: true
            },
            phone: {
                required: true,
                digits: true
            },
            clientIdentification: {
                required: true
            }
        },
        messages: {
            course: "Velg ønsket kursdato",
            name: "Du må oppgi navn",
            email: {
                required: "Oppgi e-postadresse",
                email: "Oppgi e-postadresse"
            },
            phone: {
                required: "Oppgi telefonnummer",
                digits: "Oppgi gyldig telefonnummer"
            },
            clientIdentification: "Oppgi kundenummer eller bedriftens navn"
        },
        highlight: function (element) {
            $(element).closest('.form-group').removeClass('success').addClass('error');
        },
        success: function (element) {
            $(element).closest('.form-group').removeClass('error').addClass('success');
        },
        /*errorPlacement: function () {
            return true;
        },*/
        submitHandler: function(form) {

            // lock submit
            $(form).find(':submit').attr("disabled", true);

            // send data
            $.ajax({
                type: 'POST',
                url: '/wp-content/themes/sh-2020/lib/send-course-signup-registration.php',
                data: $(form).serialize()
            })
                .done(function (response) {
                    console.log('sent success!!');

                    // show feedback
                    showCourseResponse(form, 'success', response);

                })
                .fail(function(data) {

                    // show feedback
                    showCourseResponse(form, 'error', data);

                });


        }
    });


    // update error message on select list option change
    $("#course").change(function() {
        $("#course-signup-form").validate().element("[name=course]");
    });

}




function showCourseResponse(theForm, responseType, data) {

    if (responseType == 'success') {
        //console.log('SUCCESS', data);

        // show feedback message
        $('#confirmation-msg').text(data);
        $('#confirmation-msg').delay(0).fadeIn('fast');

        // empty inputs
        resetForm('#course-signup-form');

        // activate submit
        $(theForm).find(':submit').attr("disabled", false);

    } else {
        //console.log('ERROR', data);
        // show feedback message
        $('#confirmation-msg').text(data.responseText);
        $('#confirmation-msg').delay(0).fadeIn('fast');

        // activate submit
        $(theForm).find(':submit').attr("disabled", false);
    }

}



function resetForm(theForm) {
    //console.log('resetting form now');

    // reset form error messages
    var validator = $( theForm ).validate();
    validator.resetForm();

    // clear form inputs
    $(theForm)[0].reset();


    // make the form visible again
    //$(theForm).show();
}
